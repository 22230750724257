.work-grid-slider .container.mobile {
    padding-bottom: var(--section-padding)
}

.work-grid-slider .container.mobile .row {
    justify-content: center
}

.work-grid-slider .container.mobile .row h1 {
    font-size: 40vw
}

.work-grid-slider .container.mobile .flickity-drag-info {
    padding-bottom: calc(var(--section-padding)*.6)
}

.work-grid-slider .container.mobile .flickity-drag-info .drag-info-animation {
    transform: rotate(-90deg)
}

.work-grid-slider .container.mobile .flickity-drag-info h4 span {
    font-size: .8em
}

.work-grid-slider .swiper-controls {
    pointer-events: none;
    display: none
}

.work-grid-slider .swiper-controls .btn {
    pointer-events: all
}

@media screen and (min-width:540px) {

    .work-grid-slider+.footer,
    .work-grid-slider .container.mobile {
        display: none
    }

    .work-grid-slider {
        padding: 0
    }

    .work-grid-slider .container {
        height: 100vh;
        max-width: 100vw;
        justify-content: center;
        align-items: center;
        padding: 0;
        display: flex
    }

    .work-grid-slider .swiper-controls {
        display: flex
    }
}

.flickity-slider-group[data-flickity-slider-type=cards] {
    --gap: var(--col-padding-small);
    --columns: 2.4;
    --slides-progress-width: calc(10vw + 5em);
    --slides-progress-height: 1.5em;
    --slides-amount: 0;
    --slider-info-distance: calc(5vh + 1em)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-slide {
    position: relative
}

@media screen and (max-width:1024px) {
    .flickity-slider-group[data-flickity-slider-type=cards] {
        --columns: 1.2
    }
}

@media screen and (max-width:540px) {
    .flickity-slider-group[data-flickity-slider-type=cards] {
        --columns: 1
    }

    .flickity-slider-group[data-flickity-slider-type=cards] .flickity-carousel:after {
        content: ""
    }

    .flickity-slider-group[data-flickity-slider-type=cards] .flickity-carousel {
        gap: calc(var(--row-padding)*2)var(--gap);
        flex-wrap: wrap;
        display: flex
    }

    .flickity-slider-group[data-flickity-slider-type=cards] .flickity-slide {
        margin-right: unset
    }
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-page-dots {
    width: calc(var(--slides-progress-width) + (var(--slides-progress-width)/(var(--slides-amount) - 1)));
    height: var(--slides-progress-height);
    transform: translate(-50%, var(--slider-info-distance));
    left: 50%;
    bottom: unset;
    background-color: #0000;
    border-radius: .25em;
    display: flex;
    overflow: hidden
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-page-dots .dot {
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #0000;
    border-radius: .25em;
    justify-content: center;
    align-items: center;
    margin: 0;
    display: flex
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-page-dots .dot:before {
    content: "";
    width: 1px;
    height: 100%;
    transition: all var(--animation-primary);
    background-color: rgba(var(--color-light-rgb), .5);
    border-radius: .25em;
    display: block;
    transform: scaleY(0)rotate(.001deg)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-page-dots:hover .dot:before {
    transform: scaleY(.3)rotate(.001deg)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-page-dots .dot:hover:before {
    background-color: rgba(var(--color-light-rgb), 1);
    transform: scaleY(.8)rotate(.001deg)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-progress {
    margin: var(--slider-info-distance)0;
    pointer-events: none;
    width: 100%;
    height: var(--slides-progress-height);
    justify-content: center;
    align-items: center;
    gap: 1em;
    display: flex;
    position: absolute;
    top: 100%
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-progress .flickity-progress-bar {
    width: var(--slides-progress-width);
    background: var(--color-light);
    height: 1px;
    position: relative
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-progress .flickity-progress-bar-inner {
    width: 25%;
    height: 100%;
    transition: width .2s ease-out;
    position: relative;
    left: -25%
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-progress .flickity-progress-window {
    width: 2em;
    height: var(--slides-progress-height);
    border: 1px solid var(--color-primary);
    background-color: rgba(var(--color-dark-rgb), .8);
    border-radius: .25em;
    position: absolute;
    right: 0;
    transform: translate(50%, -50%)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-slides-count {
    width: 1em;
    text-align: center;
    height: var(--slides-progress-height);
    justify-content: center;
    align-items: center;
    padding-top: .1em;
    display: flex
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-slides-count span {
    font-size: .9em
}

@media screen and (max-width:540px) {
    .flickity-slider-group[data-flickity-slider-type=cards] .flickity-progress {
        display: none
    }
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-words {
    pointer-events: none;
    justify-content: center;
    align-items: center;
    display: flex
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-words .flickity-words-group {
    width: 100%;
    pointer-events: none;
    height: .8em;
    font-size: calc(var(--title-size)*2);
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    overflow: hidden
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-words .flickity-word {
    white-space: nowrap;
    font-size: 1em;
    position: absolute;
    transform: translateY(110%)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-words .flickity-word.long {
    font-size: .8em;
    line-height: 1.1
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-words .flickity-word[data-flickity-word-status=active] {
    transition: all .6s cubic-bezier(.75, 0, .2, 1) .1s;
    transform: translateY(3%)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-words .flickity-word.animate-h1[data-flickity-word-status=active] {
    transform: translateY(3%)
}

.flickity-slider-group[data-flickity-slider-type=cards] .flickity-words .flickity-word[data-flickity-word-status=transitioning] {
    transition: all .7s cubic-bezier(.75, 0, .2, 1);
    transform: translateY(-110%)
}

@media screen and (max-width:540px) {
    .flickity-slider-group[data-flickity-slider-type=cards] .flickity-words {
        display: none
    }
}

.flickity-drag-info {
    margin: var(--slider-info-distance)0;
    pointer-events: none;
    width: 100%;
    height: var(--slides-progress-height);
    justify-content: center;
    align-items: center;
    gap: .5em;
    padding-left: 2.5em;
    transition: opacity .5s ease-in-out;
    display: flex;
    position: absolute;
    bottom: 100%
}

.flickity-drag-info h4 span {
    font-size: .7em
}

@keyframes dragAnimationFadeText {
    0% {
        opacity: .7
    }

    15% {
        opacity: 1
    }

    25% {
        opacity: 1
    }

    40% {
        opacity: .7
    }

    to {
        opacity: .7
    }
}

.drag-info-animation {
    width: 2em;
    height: 1em;
    justify-content: center;
    align-items: center;
    margin-top: .2em;
    font-size: .8em;
    animation: 3s linear infinite dragAnimationFade;
    display: flex;
    transform: scaleX(-1)
}

@keyframes dragAnimationFade {
    0% {
        opacity: 0
    }

    8% {
        opacity: 1
    }

    32% {
        opacity: 1
    }

    40% {
        opacity: 0
    }

    to {
        opacity: 0
    }
}

.drag-info-animation .circle {
    width: 1em;
    height: 1em;
    border: 1px solid var(--color-primary);
    border-radius: .5em;
    animation: 3s cubic-bezier(.75, 0, .25, 1) infinite dragAnimationCircle
}

@keyframes dragAnimationCircle {
    0% {
        width: 1em;
        transform: translate(-.5em)
    }

    20% {
        width: 2em;
        transform: translate(0)
    }

    40% {
        width: 1em;
        transform: translate(.5em)
    }
}

@media screen and (max-width:540px) {
    .flickity-slider-group[data-flickity-slider-type=cards] .flickity-drag-info {
        display: none
    }
}

.flickity-slider-group[data-flickity-slider-type=images] {
    --gap: var(--col-padding-small);
    --columns: 3.15
}

.flickity-slider-group[data-flickity-slider-type=images] .flickity-slide {
    position: relative
}

@media screen and (min-width:1024px) {
    .count-1 .flickity-slider-group[data-flickity-slider-type=images] {
        --columns: 1
    }

    .count-2 .flickity-slider-group[data-flickity-slider-type=images] {
        --columns: 2
    }

    .count-3 .flickity-slider-group[data-flickity-slider-type=images] {
        --columns: 3
    }

    :is(.count-1, .count-2, .count-3) .flickity-slider-group[data-flickity-slider-type=images] .flickity-carousel:after {
        content: ""
    }

    :is(.count-1, .count-2, .count-3) .flickity-slider-group[data-flickity-slider-type=images] .flickity-carousel {
        gap: var(--row-padding)var(--gap);
        flex-wrap: wrap;
        display: flex
    }

    :is(.count-1, .count-2, .count-3) .flickity-slider-group[data-flickity-slider-type=images] .flickity-slide {
        margin-right: unset
    }
}

@media screen and (max-width:1024px) {
    .count-1 .flickity-slider-group[data-flickity-slider-type=images] {
        --columns: 1
    }

    .count-2 .flickity-slider-group[data-flickity-slider-type=images] {
        --columns: 2
    }

    :is(.count-1, .count-2) .flickity-slider-group[data-flickity-slider-type=images] .flickity-carousel:after {
        content: ""
    }

    :is(.count-1, .count-2) .flickity-slider-group[data-flickity-slider-type=images] .flickity-carousel {
        gap: var(--row-padding)var(--gap);
        flex-wrap: wrap;
        display: flex
    }

    :is(.count-1, .count-2) .flickity-slider-group[data-flickity-slider-type=images] .flickity-slide {
        margin-right: unset
    }

    .flickity-slider-group[data-flickity-slider-type=images] {
        --columns: 2.1
    }

    .flickity-slider-group[data-flickity-slider-type=images] .flickity-carousel:after {
        content: "flickity";
        display: none
    }

    .flickity-slider-group[data-flickity-slider-type=images] .flickity-carousel {
        display: block
    }

    .flickity-slider-group[data-flickity-slider-type=images] .flickity-slide {
        margin-right: var(--gap)
    }
}

@media screen and (max-width:540px) {
    .flickity-slider-group[data-flickity-slider-type=images] {
        --columns: 1.6
    }
}

.flickity-slider-group[data-flickity-slider-type=work] {
    --gap: var(--col-padding-small);
    --columns: 2.1
}

.flickity-slider-group[data-flickity-slider-type=work] .flickity-slide {
    position: relative
}

@media screen and (max-width:1024px) {
    .flickity-slider-group[data-flickity-slider-type=work] {
        --columns: 1.6
    }
}

@media screen and (max-width:540px) {
    .flickity-slider-group[data-flickity-slider-type=work] {
        --columns: 1.1
    }
}

.flickity-slider-group[data-flickity-slider-type=vimeo] {
    --gap: var(--col-padding-small);
    --columns: 1.05
}

.flickity-slider-group[data-flickity-slider-type=vimeo] .flickity-slide {
    position: relative
}

.count-1 .flickity-slider-group[data-flickity-slider-type=vimeo] {
    --columns: 1
}

.count-1 .flickity-slider-group[data-flickity-slider-type=vimeo] .flickity-carousel:after {
    content: ""
}

.count-1 .flickity-slider-group[data-flickity-slider-type=vimeo] .flickity-carousel {
    gap: var(--row-padding)var(--gap);
    flex-wrap: wrap;
    justify-content: center;
    display: flex
}

.count-1 .flickity-slider-group[data-flickity-slider-type=vimeo] .flickity-slide {
    margin-right: unset
}

@media screen and (min-width:1024px) {
    .size-medium .flickity-slider-group[data-flickity-slider-type=vimeo] {
        --columns: 1.55
    }

    .size-small .flickity-slider-group[data-flickity-slider-type=vimeo] {
        --columns: 2.1
    }

    .size-tiny .flickity-slider-group[data-flickity-slider-type=vimeo] {
        --columns: 2.55
    }

    :is(.size-small, .size-tiny) .flickity-slider-group[data-flickity-slider-type=vimeo] .icon {
        width: max(3em, 6vw)
    }
}

:is(.size-medium, .size-small, .size-tiny) .flickity-slider-group[data-flickity-slider-type=vimeo] .vimeo-overlay-text {
    display: none
}

@media screen and (max-width:540px) {
    .flickity-slider-group[data-flickity-slider-type=vimeo] {
        --columns: 1.1
    }
}

.section-more-work-slider {
    padding-top: 0
}

.section-clients-marquee {
    padding-top: calc(var(--section-padding)*.66);
    padding-bottom: calc(var(--section-padding)*.66)
}

:root {
    --title-size: clamp(5em, 7.5vw, 7em);

}
.section-clients-marquee .marquee-item {
  
    width: 15vw;
    width: calc(var(--title-size)*2);
    margin-right: var(--col-padding-small);
    justify-content: center;
    align-items: center;
    display: flex
}

.section-clients-marquee .marquee-item :is(img, svg) {
    max-width: 50%;
    max-height: 50%;
    opacity: .4
}

.section-clients-marquee .marquee-item svg :is(path, polyline, line, polygon, circle, rect, ellipse) {
    fill: var(--color-white)
}

.section-clients-marquee .marquee-item span {
    opacity: 0;
    visibility: hidden;
    position: absolute
}

/* @media screen and (max-width:1980px) {
    .section-clients-marquee .marquee-item {
        width: 15vw
    }
} */

@media screen and (max-width:1024px) {
    .section-clients-marquee .marquee-item {
        width: 20vw
    }
}

@media screen and (max-width:540px) {
    .section-clients-marquee .marquee-item {
        width: 25vw
    }

    .section-clients-marquee .marquee-item :is(img, svg) {
        max-width: 66%;
        max-height: 66%;
        opacity: .4
    }
}